<template>
<div>
  <DocumentTable  @search="onSearch" :documents="documents" :headers="headers" />
</div>
</template>

<script>
import DocumentTable from "@/components/widgets/documentTable/DocumentTable";
import {useStore} from "vuex";
import {computed} from 'vue'
export default {
  name: "ProductionDocuments",
  components: {DocumentTable},
  setup() {
    const store = useStore()
    return {

      documents: computed(() => store.getters.getProductionDocuments),
      headers: computed(() => ['title', 'date', 'version', 'documentType', 'department']),
      async onSearch(form){
        await store.dispatch('fetchProductionDocuments', {
          productionId:store.getters.getProductionId,
          documentTitle: form.title,
          documentType: form.type,
          documentDepartment: form.department,
          sortBy: form.sortBy
        })
      }
    }
  }
}
</script>
