<template>
  <div class="w-full">
    <main class="grid grid-cols-10">
      <div class="w-full grid col-span-10 bg-white py-8">
        <ProfileHeader :profile="profileHeader"/>
      </div>
      <ProfileNavbar class="col-start-3 col-span-6" @change="changeComponent" :navigation="navigation"/>
      <div class="flex justify-end items-end col-start-9 w-full mb-4">
        <PencilIcon class="pl-4 pr-2 h-1/4 text-gray-500" />
        <button @click="enterEdit" class=" pr-4 text-sm font-medium rounded-md text-gray-500  dark:bg-primaryDark dark:text-white">Edit</button>
      </div>
      <div class="col-start-3 col-span-7">
        <transition name="fade">
          <component :is="currentComponent" class="mr-20"/>
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import {useStore} from "vuex";
import { useRouter, useRoute } from "vue-router";
import {computed, reactive, ref, watch} from "vue";
import { PencilIcon } from '@heroicons/vue/outline'
import ProfileHeader from "@/components/widgets/profileHeader/ProfileHeader";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import ProductionCalendar from "@/components/productions/viewProduction/ProductionCalendar";
import ProductionOverview from "@/components/productions/viewProduction/ProductionOverview";
import ProductionDocuments from "@/components/productions/viewProduction/ProductionDocuments";
import ProductionMedia from "@/components/productions/viewProduction/ProductionMedia";
import ProductionPeople from "@/components/productions/viewProduction/ProductionPeople";

const navigation = ref([
  { name: 'Overview', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionOverview', current: true },
  { name: 'People', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionPeople', current: false },
  { name: 'Media', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionMedia', current: false },
  { name: 'Documents', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionDocuments', current: false },
  { name: 'Calendar', onClick:function(){onNavClick(this, navigation.value)}, component: 'ProductionCalendar', current: false },
])
function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

export default {
  name: "Production",
  components: { ProfileHeader, ProfileNavbar, ProductionCalendar,
  ProductionOverview, ProductionDocuments, ProductionMedia, ProductionPeople, PencilIcon},

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    function fetchProduction(){
      store.dispatch('fetchProduction', route.params.id)
    }

    watch(
        () => route.params.id,
        (id, prevId) => {
          if(id !== prevId) fetchProduction()
        }
    )

    fetchProduction()

    const profileHeader = reactive({
      logo: computed(() => store.getters.getProductionLogo),
      title: computed(() => store.getters.getProductionName),
      subTitle: "By Thorbjorn Egner",
      location: "Þjóðleikhúsið"
    })

    let currentComponent = ref('ProductionOverview')

    return {
      store,
      router,
      currentComponent,
      profileHeader,
      navigation,
      changeComponent(component){
        currentComponent.value = component
      },
      enterEdit() {
        router.push({ name: 'EditProduction', params:{id:store.getters.getProductionId}})
      },
    }
  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++){
      this.navigation[i].current = false
    }
    this.navigation[0].current = true
    // this.$store.commit('resetProduction')
  }
}
</script>
