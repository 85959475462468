<template>
  <div class="mt-8">
    <div class="flex items-start flex-col">
      <DynamicTable
          @prev="onPreviousPage"
          @download="downloadContactList"
          :downloadable="true"
          :filters="[]" @next="onNextPage"
          :link="link"
          :update="update"
          :button="button"
          :searchFunc="onSearchParamEntry"
          class="w-full"
      />
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import { useRouter} from "vue-router";

export default {
  name: "ProductionPeople",
  components: { DynamicTable },
  setup() {
    const store = useStore()
    const router = useRouter()
    store.dispatch('dynamicTableLoadProductionMembers')

    function onProfileView(prodMemId){
      router.push({ name: 'Profile', params: { id: findProfileId(prodMemId)[0].profileId }})
    }

    function findProfileId(id){
      return store.getters.getDynamicTableItems.filter( el => el.id == id)
    }

    async function update(){
      await store.dispatch('dynamicTableLoadProductionMembers')
    }

    async function onNextPage() {
      await store.dispatch('productionMembersPageNext')
    }

    async function onPreviousPage() {
      await store.dispatch('productionMembersPagePrevious')
    }

    async function onSearchParamEntry(searchString) {
      store.commit('resetDynamicTableSearchParams')
      store.commit('setDynamicTableSearchParam', searchString)
      await store.dispatch('dynamicTableLoadProductionMembers')
    }

    return {
      onProfileView,
      update,
      onNextPage,
      onPreviousPage,
      onSearchParamEntry,
      link: computed(() => {
        return {func: onProfileView, label: "View Profile", fieldName:'Name'}
      }),
      button: computed(() => {
        return {func:console.log, label: "Edit"}
      }),
      async downloadContactList(){
        const file = await store.dispatch('fetchProductionContactList', store.getters.getProductionId)
        const link = document.createElement('a')
        link.href=file
        link.target='_blank'
        link.click()
      }
    }
  },
  unmounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>
