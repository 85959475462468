<template>
<div class="bg-white mt-12">
  <div class="flex w-1/2 ">
    <button class="w-20 h-12 flex justify-center" :class="[false ? 'bg-gray-50 text-gray-700 hover:text-indigo-700 hover:bg-white border-b-2 border-gray-500 dark:bg-backgroundDark' : 'border-b-2 border-gray-200 text-gray-400 hover:text-gray-900 hover:bg-gray-50',  'px-3 py-2 flex items-center text-sm font-medium dark:text-white']">
      Albums
    </button>
    <button @click="page=2" class="w-20 h-12 flex justify-center" :class="[page===2 ? 'bg-gray-50 text-gray-700 hover:text-indigo-700 hover:bg-white border-b-2 border-gray-500 dark:bg-backgroundDark' : 'border-b-2 border-gray-200 text-gray-400 hover:text-gray-900 hover:bg-gray-50',  'px-3 py-2 flex items-center text-sm font-medium dark:text-white']">
      Photos
    </button>
    <button @click="page=3" class="w-20 h-12 flex justify-center" :class="[page===3 ? 'bg-gray-50 text-gray-700 hover:text-indigo-700 hover:bg-white border-b-2 border-gray-500 dark:bg-backgroundDark' : 'border-b-2 border-gray-200 text-gray-400 hover:text-gray-900 hover:bg-gray-50',  'px-3 py-2 flex items-center text-sm font-medium dark:text-white']">
      Videos
    </button>
  </div>
  <transition-group name="fade">
    <PhotoGallery v-if="page===2" :key="2" :images="images" />
    <VideoGallery v-if="page===3" :key="3" :videos="videos" />
  </transition-group>
</div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import PhotoGallery from "@/components/widgets/photoGallery/PhotoGallery";
import VideoGallery from "@/components/widgets/videoGallery/VideoGallery";

export default {
  name: "ProductionMedia",
  components: {VideoGallery, PhotoGallery},
  setup() {
    const store = useStore()
    const page = ref(2)
    return {
      page,
      images: computed(() => store.getters.getProductionImages),
      videos: computed(() => store.getters.getProductionVideos)
    }
  }
}
</script>
