<template>
  <div>
    <button v-on:click="openModal" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Add Event</button>
    <CreateEventModal ref="CreateEventModal"/>
    <FullCalendar :options="calendarOptions"  />
  </div>
</template>

<script>
import FullCalendar from "../../widgets/fullcalendar/FullCalendar";
import CreateEventModal from "../../widgets/fullcalendar/CreateEventModal";
import {useStore} from "vuex";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from  '@fullcalendar/list';

export default {
  name: "ProductionCalendar",
  components: {
    FullCalendar,CreateEventModal,
  },
   data() {
    const store = useStore()
    const events = []
    //const originalEvents = store.getters.getEvents
    const calEvent = store.getters.getCalEvents
    for(let i =0; i < calEvent.length; i++)
    {events.push( {
      id: calEvent[i].id,
      title: calEvent[i].title ,
      start: calEvent[i].startTime,
      end: calEvent[i].endTime,
      borderColor: '#000000' ,
      editable: true,
    })}

    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin,listPlugin ],
        initialView: 'dayGridMonth',
        selectable: true,
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        editable: true,
        events: events,
        eventColor: 'blue',
        dateClick: this.openModal, //make date come automatically
        eventClick: async function(info){
          confirm( info.event.title + '\n Happening at: ' + info.event.start.toLocaleString() + '\n Ends at: ' + info.event.end.toLocaleString())
          const areSure = confirm('Would you like to remove Event ' + info.event.title + "?")
          if (areSure == true) {
            const idToDelete = info.event.id
            await store.dispatch('removeEvent', idToDelete)
          } else {console.log('canceled')}
             //here i will have to reFetch events
        },
        eventDrop: async function(info){
          alert(info.event.title + " was dropped on " + info.event.start.toLocaleString())
        }
      },
      calEvent,
    }
  },
  methods: {
    openModal() {
      this.$refs.CreateEventModal.Isopen();
    },
  },

};
</script>

<style>

</style>