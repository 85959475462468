<template>
  <div class="dark:text-white " :class="[!subNav ? '' : ' ' ,isMobile?' lg:items-center gap-x-2 justify-center lg:justify-between flex-row-reverse lg:flex-row-unset	':'']">
    <nav
      :class="[noHide ? 'flex flex-row mx-auto' : 'hidden lg:flex lg:flex-row lg:mx-0', version == '2' ? 'flex-wrap float-left' : '']"
      class=" dark:bg-primaryDark" >
       <button v-for="item in navigation" 
       :key="item.name" 
       @click="item.onClick(item); $emit('change', item.component)"
        class="py-2  px-3 xl:px-5 flex items-center dark-hover:text-gray-500 text-sm font-medium dark:text-white w-fit h-12 rounded-t-lg justify-center dark:border-white dark:text-white"
        :class="[fetchButtonClass(item),(item.component =='Professional' || item.component =='CompanySettings')?'hidden':'']" >
        <span class="flex  place-items-center">
          <!-- <span v-if="isIconVisible"> -->
           <!-- <component class="w-4 mt-0.5 mr-5" :is="item.icon" /> -->
          <!-- </span> -->
          {{ item.name }}
        </span>
      </button>
     
    </nav>
     <div :class="[noHide ? 'hidden' : 'lg:hidden cursor-pointer w-8 hidden']">
      <!-- <div class="flex justify-center">
        <MenuIcon v-if="!open" class="h-10 w-10 w-full text-gray-400 py-2" @click="open = !open" />
        <MenuIcon v-else class="h-10 w-10 py-2 text-gray-400 w-full" @click="open = !open" />
      </div>
     -->
      <div v-if="isMobile" class="col-span-3 flex place-self-center dark:text-gray-400 text-gray-500 ">
          <!-- <font-awesome-icon icon="fa-solid fa-pencil" class="self-center mr-2 text-gray-400"/> -->
          <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" @click="editModeActive = true">Edit Header</button>
        </div>
      <nav v-if="open" :class="[noHide ? 'hidden' : '2xl:hidden']"  class="dark:bg-primaryDark dark:text-white rounded-r-md rounded-l-md flex flex-col mx-auto box-shadow-custom absolute w-full left-0 p-2.5 bg-white mt-2.5 z-10">
      
       <button class="sm-full lg:w-28 h-12 flex text-left justify-left px-3 xl:px-5" v-for="items in navigation" :key="items.name"
          @click="items.onClick(items); $emit('change', items.component); open = !open"
          :class="[items.current  ? ' text-black hover:bg-white  dark:bg-primaryDark'
          : '  hover:text-gray-900 hover:bg-gray-50', 'hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white']">
                 <!-- <component :is="item.icon" :class="[items.current ? 'text-indigo-500 group-hover:text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" /> -->
          <span class="flex place-items-center">
            <!-- <span v-if="isIconVisible"> -->
              <!-- <component class="w-4 mt-0.5 mr-5" :is="items.icon" /> -->
            <!-- </span> -->
            {{ items.name }}
          </span>
        </button>
      </nav>
        
    </div>
    <slot></slot>
  </div>
  
</template>
<script>
// import { MenuIcon } from "@heroicons/vue/outline";
// import { MenuIcon } from "@heroicons/vue/outline/solid";
import { ref,computed } from "vue";

// import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
export default {
  name: "ProfileNavbar",
  // components: { MenuIcon},
  
  props: {
    navigation: {
      type: Array,
    },
    noHide: {
      type: Boolean,
      default: false
    },
    editMode:{
      type: Boolean,
      default: false
    },
    subNav: {  // Is the navbar nested within another navbar?
      type: Boolean,
      default: false
    },
    version: {
      required: false,
      default: "1"
    },
    isIconVisible: {
      required: false,
      default: false
    }
  },
  // methods()
  setup(props) {
    const open = ref(false)
    const { width } = useBreakpoints();
    return {
      open,
      isMobile: computed(() => width.value < 1024),
      fetchButtonClass(btnItem) {
        // TODO:  This might need to be cleaned up or changed.
        //        A lot going on here.
        if (props.subNav) {
          if (btnItem.current) {
            return ' '
          } else {
            return 'text-gray-400 hover:text-gray-900 hover:bg-gray-50  dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500 dark:text-gray-400'
          }
        } else {
          if (btnItem.current) {
            // TODO: Maybe add accent color underline to current element?
            return ' text-gray-700 hover:bg-white border-b-2 border-gray-500 dark:bg-primaryDark dark-hover:text-gray-500 dark-hover:bg-primaryDark'
          } else {
            return 'text-gray-400 border-b-2 border-primary dark:border-primaryDark hover:text-gray-900 hover:bg-gray-50 dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500'
          }
        }
      }
    }
  }
}
</script>