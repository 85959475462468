<template>
    <div v-if="open" class="fixed inset-0 z-40 " @close="open =false">
      <div class="fixed inset-0 ">
        <div class="absolute inset-0" />
        <div class="fixed flex inset-0 items-start justify-center overflow-scroll ">
            <div class="w-screen max-w-md">
              <form  @submit.prevent="onSubmit" class="h-1/2 divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <div class="text-lg font-medium text-white">
                        New Event
                      </div>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-indigo-300">
                        Get started by filling in the information below to create your new event.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                          <div>
                            <SmallTextInput heading="Title" :value="state.title" @update:value="state.title=$event" :errors="v$.title.$errors" />
                            <div class="mt-1">
                            <SelectInput heading="Public" @update:value="state.public=$event" :value="state.public" :options="isPublic" :errors="v$.public.$errors" />
                          </div>
                            <div class="mt-1">
                              <SelectInput heading="Venue" @update:value="state.public=$event" :value="state.venue" :options="venues"  />
                            </div>
                            <div class="mt-1">
                              <SelectInput heading="Space" @update:value="state.public=$event" :value="state.space" :options="spaces"   />
                            </div>
                            <div class="mt-1">
                              <SelectInput heading="Event Type" @update:value="state.eventType=$event" :value="state.eventType" :options="eventTypes" :errors="v$.eventType.$errors" />
                            </div>
                            </div>
                          <div class="mt-1">
                            <LargeTextInput heading="Notes" :value="state.notes" @update:value="state.notes=$event"   />
                          </div>
                          <div class="mt-1">
                          <DateTimeInput heading="Start Date" :value="state.startTime" @update:value="state.startTime=$event" :errors="v$.startTime.$errors" />
                          </div>
                          <div class="mt-1">
                          <DateTimeInput heading="End Date" :value="state.endTime" @update:value="state.endTime=$event" :errors="v$.endTime.$errors" />
                          </div>
                        <div>
                          <h3 class="text-sm font-medium text-gray-900">
                            Invite People
                          </h3>
                          <div class="mt-2">
                            <div class="flex space-x-2">
                              <a v-for="person in team" :key="person.email" :href="person.href" class="rounded-full hover:opacity-75">
                                <img class="inline-block h-8 w-8 rounded-full" :src="person.imageUrl" :alt="person.name" />
                              </a>
                              <button type="button" class="flex-shrink-0 bg-white inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span class="sr-only">Add team member</span>
                                <PlusIcon class="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    Cancel
                  </button>
                  <button  type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Save
                  </button>
                </div>
              </form>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import {reactive, ref} from 'vue'
import { XIcon } from '@heroicons/vue/outline'
import { PlusIcon } from '@heroicons/vue/solid'
import SelectInput from "../../inputs/SelectInput";
import SmallTextInput from "../../inputs/SmallTextInput";
import LargeTextInput from "../../inputs/LargeTextInput";
import DateTimeInput from "../../inputs/DateTimeInput";
import {useStore} from "vuex";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { eventTypes } from "./constants";



const team = [
  {
    name: 'Tom Cook',
    email: 'tomcook@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Whitney Francis',
    email: 'whitneyfrancis@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Leonard Krasner',
    email: 'leonardkrasner@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Floyd Miles',
    email: 'floydmiles@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: 'Emily Selman',
    email: 'emilyselman@example.com',
    href: '#',
    imageUrl:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
]

export default {
  name: 'Modal',
  components: {
    LargeTextInput,
    SmallTextInput,
    DateTimeInput,
    PlusIcon,
    XIcon,
    SelectInput,
  },
  setup() {
    const open = ref(false)
    const store = useStore()
    const venues = store.getters.getCalVenues // NOT READY get venue names for options but send in venue id
    const spaces = store.getters.getCalSpaces // NOT READY get space names for options but send in space id
    const currentEvent = store.getters.getEvent
    const productionID = store.getters.getProductionId
    const isPublic = [Boolean(true), Boolean(false)]
    const state = reactive({
      title: currentEvent.name,
      startTime: currentEvent.startTime,
      endTime: currentEvent.endTime,
      notes: currentEvent.notes,
      eventType: currentEvent.eventType,
      production: productionID,
      public: currentEvent.public,
      venue: currentEvent.venue,
      space: currentEvent.space
    })
    const rules = {
      title: { required },
      startTime: { required },
      endTime: { required },
      notes: { required },
      production: {required},
      eventType: {required},
      public: {required}

    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    return {
      v$,
      state,
      team,
      open,
      isPublic,
      eventTypes,
      venues,
      spaces,
    }
  },

  methods: {
    async onSubmit() {
      await this.$store.dispatch('createEvent', this.state)
      this.open = false;
    },
    Isopen () {
      this.open = true;
    },
  },


}
</script>