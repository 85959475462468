<template>
  <div>
    <div @click="close" class="flex flex-wrap justify-evenly bg-white ">
      <div v-for="(vid,idx) in videos" :key="vid.id" class="m-6">
        <span class="max-w-1/4" v-html="vid.player.element" ></span>
        <div v-if="edit && isCreator">
          <input type="checkbox" :value="idx" v-model="checkedVideos" @change="emitVideos"/>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "VideoGallery",
  props: {
    videos: Array,
    edit: Boolean
  },
  data(){
    return{
      checkedVideos: [],
    }
  },
  methods: {
    emitVideos() {
      this.$emit('update:parent',this.checkedVideos)
    }
  },
  setup() {
    const store = useStore()

    return {
      isCreator: computed(() => store.getters.getId === store.getters.getProfileId),
    }
  }
}
</script>

<style scoped>

</style>