<template>
<div class="w-full grid grid-cols-8 gap-4">
<!--  <div id="offsett" class="md:w-1/4 h-2 mr-4 sm:w-0 sm:h-0 sm:m-0 "></div>-->
  <div id="main-content" class="col-span-5">
    <TextDisplay title="About this production" :body="production.description" />
    <TextDisplay title="Synopsis" :body="production.synopsis" />
    <ImageDisplay v-if="images.length > 0" :images="images" />
    <ProductionPeople :people="production.productionMembers" :edit="false"/>

  </div>
  <div id="side-content" class="col-span-3">
    <ProductionAbout :production="production" :edit="false" />
    <ProductionWebsites :websites="production.websites" :edit="false"/>
    <ProductionReviews :reviews="production.reviews" :edit="false" />

  </div>
</div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import TextDisplay from "@/components/widgets/TextDisplay";
import ImageDisplay from "@/components/widgets/ImageDisplay";
import ProductionAbout from "@/components/productions/components/ProductionAbout";
import ProductionWebsites from "@/components/productions/components/ProductionWebsites";
import ProductionReviews from "@/components/productions/components/ProductionReviews";
import ProductionPeople from "@/components/productions/components/ProductionPeople";

export default {
  name: "ProductionOverview",
  components: {ProductionPeople, ProductionReviews, ProductionWebsites, ProductionAbout, ImageDisplay, TextDisplay},
  setup() {
    const store = useStore()
    const production = computed(() => store.getters.getProduction)
    return {
      production,
      images: computed(() => store.getters.getPreviewProductionImages)
    }
  }
}
</script>

