<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'

export default {
  components: {
    FullCalendar
  },

}
</script>
<template>
  <FullCalendar />
</template>