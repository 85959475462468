<template>
  <div class="col-span-6 sm:col-span-3">
    <label class="block text-sm font-medium text-gray-700 dark:text-white ">{{ heading }}</label>
    <input :value="value" @input="$emit('update:value', $event.target.value)" type="datetime-local" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark dark:text-white"/>
  </div>
</template>

<script>
export default {
  name: "DateTimeInput",
  props: {
    heading: String,
    value: String,
  }
}
</script>

<style scoped>

</style>