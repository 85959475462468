<!-- WARNING:   This is probably not the ProfileHeader you are looking for.
                The other ProfileHeader is at:

                @/components/profile/components/profileHeader/ProfileHeader
                
 -->
<template>
  <div class="flex dark:bg-primaryDark dark:text-white">
    <div class="w-full grid grid-cols-10">
      <div v-if="edit" class="flex flex-col items-center col-start-2 col-span-1">
        <img :src="profile.logo.value" class="rounded-full h-32 w-32 justify-self-end col-start-2 col-span-1 mr-4">
        <button @click="$refs.file.click()" class="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-backgroundDark dark:text-white">Change Picture</button>
        <input @change="profile.logo.changeFunc($event.target.name, $event.target.files)" name="file-input" ref="file" type="file" class="hidden" />
      </div>
      <img v-else :src="profile.logo" class="rounded-full h-32 w-32 justify-self-end col-start-2 col-span-1 mr-4">

      <div id="profileHeader-content" class=" tracking-wide w-full col-span-7">

        <h1 class="text-3xl font-medium text-gray-900 dark:text-white">{{profile.title}}</h1>

        <div class="flex text-md border-b border-gray-600 pb-2 mb-1">
          <h3>{{profile.subTitle}}</h3> <h3 v-if="profile.subTitle2">, {{profile.subTitle2}}</h3>

          <div v-if="profile.location" class="flex ml-4 ">
            <LocationMarkerIcon class="ml-3 mr-1 text-gray-400 w-7" />
            <select v-if="edit" @change="profile.location.setPrimaryFunc($event.target.value)" class="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-primaryDark dark:text-white" :value="profile.location.value">
              <option v-for="opt in profile.location.options" :key="opt.id" :value="opt.id">{{opt.city}}</option>
            </select>
            <h3 v-else>{{profile.location}}</h3>
          </div>
        </div>

        <div class="flex justify-start text-lg">
          <div v-if="profile.phone" class="mr-2">
            <select v-if="edit" @change="profile.phone.setPrimaryFunc($event.target.value)" class="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-m dark:bg-primaryDark dark:text-white" :value="profile.phone.value">
              <option v-for="opt in profile.phone.options" :key="opt.id" :value="opt.id">{{opt.phoneNumber}}</option>
            </select>
            <p v-else>{{profile.phone}}</p>
          </div>


          <div v-if="profile.email" class="mx-2">
            <select v-if="edit" @change="profile.email.setPrimaryFunc($event.target.value)" class="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-primaryDark dark:text-white" :value="profile.email.value">
              <option v-for="opt in profile.email.options" :key="opt.id" :value="opt.id">{{opt.email}}</option>
            </select>
            <p v-else>{{profile.email}}</p>
          </div>

          <div v-if="profile.website" class="mx-2">
            <select v-if="edit" @change="profile.website.setPrimaryFunc($event.target.value)" class="w-full mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-primaryDark dark:text-white" :value="profile.website.value">
              <option v-for="opt in profile.website.options" :key="opt.id" :value="opt.id">{{opt.url}}</option>
            </select>
            <p v-else>{{profile.website}}</p>
          </div>
        </div>
        <div class="flex h-10">
          <a v-for="(link, idx) in profile.links" :key="idx" :href="link">
            <img src="@/assets/logo.png" class="h-full mr-4 mt-2" />
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { LocationMarkerIcon } from '@heroicons/vue/solid'
//TODO This header is generic. Replace with custom headers for each resource

export default {
  name: "ProfileHeader",
  components: { LocationMarkerIcon},
  props: {
    profile: {
      type: Object,
      required: true
    },
    edit: Boolean
  },
}
</script>
