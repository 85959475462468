<template>
  <div class="my-10 bg-white p-6 rounded-md">
    <h2 class="text-xl font-medium mb-4">{{title}}</h2>
    <p class="text-md">{{body}}</p>
  </div>
</template>

<script>
export default {
  name: "TextDisplay",
  props: {
    title: String,
    body: String
  }
}
</script>
